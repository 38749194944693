<template>
    <div class="privacy">
        <Header @logout="logout" v-if="user !== null"></Header>

        <div class="privacy-container">

            <div class="container">
                <h1 class="title">Privacy Policy</h1>

                <p class="content">
                    FindLINC recognizes the significance of the privacy of every user who visits the website as well as the importance of informing you on how the website works and how your data will be used. As such, we highly suggest that you carefully go through this Privacy Policy before using the website.
                </p>
                <p class="content">
                    This Privacy Policy discloses the privacy practices of, and for. FindLINC and sets out how FindLINC uses and protects any information that you give when you use the website.
                </p>
                <p class="content">
                    Furthermore, the way this website processes, stores and protects user data and information will also be detailed within this policy such as (but not limited to):
                    <ul>
                        <li>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</li>
                        <li>What choices are available to you regarding the use of your data.</li>
                        <li>The security procedures in place to protect the misuse of your information.</li>
                        <li>How you can correct any inaccuracies in the information.</li>
                    </ul>
                </p>
                <p class="content">
                    FindLINC reserves the right to modify, change, and update this policy at any time we deem necessary. For this reason, we encourage you to consult the Privacy Policy regularly to stay informed as to how we are protecting your personal information. We will also be notifying of any material changes to this Privacy Policy, either through the website or through email if you are registered with us.
                </p>
            </div>

            <div class="container">
                <h1>Information Collection, Use, and Sharing</h1>
                <p class="content">
                    FindLINC is committed to ensuring that your privacy is protected. While users can openly view our website, registration and provision of identifiable personal information is required in order to have full access on the site.
                </p>
                <p class="content">
                    Should we ask you to provide certain information (eg. name and email address) by which you can be identified when using this website will be for the purpose of promoting marketing campaigns, advertising, giving updates on new features, upcoming events that may be of interest to you, to streamline updates, and for customer service reasons.
                </p>
                <p class="content">
                    Moreover, should you choose to select any FinLINC-sponsored links to social media platforms found on our site or accessing our site using any of your social media accounts, every information that these social media channels collect is subject to their respective Privacy Policies. Although, some of that information will be shared with FindLINC, which will be treated in accordance with this Privacy Policy.
                </p>
                <p class="content">
                    We may also be collecting certain aggregate and non-personal information which will be used to identify the number of visitors in the site, the various pages accessed by a particular visitor IP address, type of browser used, date and time of access, etc. This information will help us improve the site and tailor it to facilitate ease of access and improve user journey.
                </p>
                <p class="content">
                    But rest assured that it will only be used in accordance with this privacy statement. We are the sole owners of the information collected on this site. You have the control over who sees what you share on FindLINC. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
                </p>
            </div>

            <div class="container">
                <h1>Access to and Control Over Information</h1>
                <p class="content">
                    We may request you to provide certain types of information which would deem important to make way for improvement in order for the site to better serve you. However, you may opt out of any future contacts from us at your discretion. You can do the following at any time by contacting us via email or phone number provided in our website for the following:
                </p>

                <ul class="content no-style">
                    <li>See what data we have about you, if any.</li>
                    <li>Change and/or correct any data we have about you.</li>
                    <li>Have us delete any data we have about you.</li>
                    <li>Express any concern you have about our use of your data.</li>
                </ul>
            </div>

            <div class="container">
                <h1>Security</h1>
                <p class="content">
                    Protecting your information and privacy is an utmost concern to us. We take every physical, technical and organizational precautions to protect your information from misuse and unauthorized access.
                </p>
                <p class="content">
                    We store every information you provide on our secured servers and use encryption to protect sensitive information to keep them protected online. We also protect your information offline by limiting access of your information to our highly-trained employees who may find the need to access personally identifiable information to perform a specific job.
                </p>
                <p class="content">
                    While we want you to have full confidence in using our website, we cannot 100% warrant our security measures meeting, or even exceeding, any specific standards, especially against the interception of third parties and the constant evolution of new technology. But rest assured that we are doing our best efforts to make optimum, error-free sites, counteract any breach of security, and ensure that all data is free from vulnerabilities.
                </p>
                <p class="content">
                    All data and communication within FindLINC is secure and encrypted. You can verify this by looking for a lock icon in the address bar or looking for "https'' at the beginning of the address of the website.
                </p>
                <p class="content">
                    If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at  <strong>+886-927-511092 or findlifeinterest@gmail.com.</strong>
                </p>
            </div>
        </div>
        
        <Footer v-if="user !== null"></Footer>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    components: { 
        Header: () => import('@/components/utils/Header'),
        Footer: () => import('@/components/utils/Footer')
    },

    computed: {
        ...mapFields(['user'])
	},

    methods: {
        logout () {
            this.$store.dispatch('logout')
        }
    }
}
</script>

<style lang="scss" scoped>
.privacy {
    width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

    .privacy-container {
        background-image: url('~@/assets/img/home_upperbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 180px 100px 40px;

        .container {
            margin-bottom: 50px;
        }

        h1 {
            text-align: left;
            color: #221E1F;
            font-family: 'Crimson Pro', sans-serif;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 40px;

            &.title {
                font-size: 30px;
                text-align: center;
            }
        }
    
        .content {
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 20px;
            line-height: 26px;

            ul {
                margin-top: 10px;

                li {
                    margin-left: 15px;
                    padding-left: 25px;
                    list-style-type: disc;
                }

                &.no-style {

                    li {
                        list-style-type: none;
                        margin-left: 0px;
                        padding-left: 0px;
                    }
                }
            }
        }
    }

}
</style>